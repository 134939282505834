import {Environment} from "../app/types/interfaces";

export const environment: Environment = {
  production: true,

    apiUrl: 'https://myapi.greencountry.com.ua/api',
    webUrl: 'https://myapi.greencountry.com.ua/',
    host: 'https://my.greencountry.com.ua/',


};
