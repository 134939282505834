import {NgModule} from "@angular/core";

import {ComponentsModule} from "./components/components.module";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {TabsComponent} from "./components/tabs/tabs.component";
import {TabComponent} from "./components/tab/tab.component";
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {RouterLink} from "@angular/router";
import {StudentHeaderComponent} from "./components/header/student-header/student-header.component";
import {SemiHeaderComponent} from "./components/semi-header/semi-header.component";
import {NextLessonComponent} from "./components/lesson/next-lesson/next-lesson.component";
import {InfoComponent} from "./components/group/info/info.component";
import {RoundProgressComponent} from "angular-svg-round-progressbar";
import {CertificateComponent} from "./components/certificate/certificate.component";
import {CalendarComponent} from "./components/calendar/calendar.component";
import {ChangedCabinetComponent} from "./components/modals/changed-cabinet/changed-cabinet.component";
import {ViewTestComponent} from "./components/modals/view-test/view-test.component";
import {PracticingComponent} from "./components/modals/chose-group/practicing/practicing.component";
import {BirthdayCardComponent} from "./components/modals/birthday-card/birthday-card.component";
import {InfoComponentModal} from "./components/modals/info/info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TransferLessonsComponent} from "./components/modals/transfer-lessons/transfer-lessons.component";
import {FeedbackComponent} from "./components/feedback/feedback.component";



@NgModule({
    imports: [
        ComponentsModule,
        TranslocoDirective,
        TranslocoPipe,
        NgForOf,
        NgIf,
        NgClass,
        IonicModule,
        RouterLink,
        RoundProgressComponent,
        AsyncPipe,
        NgOptimizedImage,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        ComponentsModule,
        TranslocoDirective,
        TranslocoPipe,
        TabsComponent,
        TabComponent,
        StudentHeaderComponent,
        SemiHeaderComponent,
        NextLessonComponent,
        CertificateComponent,
        CalendarComponent,
        InfoComponent,
        ChangedCabinetComponent,
        ViewTestComponent,
        PracticingComponent,
        BirthdayCardComponent,
        InfoComponentModal,
        TransferLessonsComponent,
        FeedbackComponent
    ],
    declarations: [
        TabsComponent,
        TabComponent,
        StudentHeaderComponent,
        SemiHeaderComponent,
        NextLessonComponent,
        InfoComponent,
        CertificateComponent,
        CalendarComponent,
        ChangedCabinetComponent,
        ViewTestComponent,
        PracticingComponent,
        BirthdayCardComponent,
        InfoComponentModal,
        TransferLessonsComponent,
        FeedbackComponent
    ]
})
export class SharedModule {
}
